
.download {
  height: calc(100vh - 64px);
  overflow: auto;
  width: 100vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  &__table {
    width: calc(100% - 60px);
    height: calc(100% - 40px);

    > div {
      width: 100%; }

    &__text {
      color: var(--mdc-theme-text-secondary-on-background);
      margin: 10px; } } }


.react-drawer {
  height: calc(100vh - 64px) !important; }

.react-drawer-link {
  color: inherit;
  text-decoration: inherit; }


.mdc-fab {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 2; }


@media(min-width: 1024px) {
  .mdc-fab {
    bottom: 1.5rem;
    right: 1.5rem; } }

.mdc-snackbar--error > .mdc-snackbar__surface {
  background: var(--mdc-theme-error);
  color: var(--mdc-theme-on-error); }


.mdc-snackbar--error > .mdc-snackbar__surface * {
  color: var(--mdc-theme-on-error) !important; }


.mdc-top-app-bar {
  color: var(--mdc-theme-on-primary) !important; }


.mdc-drawer {
  background: var(--mdc-theme-background) !important; }


.mdc-button:disabled {
  color: var(--mdc-theme-text-disabled-on-background); }


.mdc-drawer__title,
.mdc-drawer .mdc-list-item__graphic,
.mdc-drawer .mdc-list-item__text,
.mdc-data-table__cell,
.mdc-data-table__header-cell,
.mdc-chip__icon--trailing,
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-text-primary-on-background) !important; }


.mdc-chip {
  background-color: var(--mdc-theme-chip); }


.mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate) ~ .mdc-checkbox__background,
.mdc-select:not(.mdc-select--disabled) .mdc-line-ripple::before {
  border-color: var(--mdc-theme-text-disabled-on-background); }


.mdc-select:not(.mdc-select--disabled):hover .mdc-line-ripple::before {
  border-color: var(--mdc-theme-text-secondary-on-background); }


.mdc-checkbox.mdc-checkbox--disabled ~ label {
  color: var(--mdc-theme-text-disabled-on-background); }


.mdc-tab-indicator,
.mdc-text-field.mdc-text-field--outlined:not(.mdc-text-field--disabled) {
  background-color: var(--mdc-theme-background) !important; }

.mdc-chip__text,
.mdc-chip__icon {
  color: var(--mdc-theme-text-primary-on-background); }


.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input,
.mdc-list-item, .mdc-checkbox,
.mdc-select:not(.mdc-select--disabled) .mdc-select__selected-text {
  color: var(--mdc-theme-text-primary-on-light) !important; }


.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label,
.mdc-select:not(.mdc-select--disabled) .mdc-floating-label,
.mdc-select-helper-text,
.mdc-text-field-helper-text {
  color: var(--mdc-theme-text-secondary-on-background) !important; }


.mdc-text-field--focused:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid) .mdc-floating-label,
.mdc-drawer .mdc-list-item--activated .mdc-list-item__graphic,
.mdc-drawer .mdc-list-item--activated,
.mdc-select--activated div .mdc-floating-label.mdc-floating-label--float-above,
.mdc-select--focused div .mdc-floating-label.mdc-floating-label--float-above,
.mdc-select--activated div .mdc-select__dropdown-icon {
  color: var(--mdc-theme-primary) !important; }


.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):not(.mdc-text-field--invalid) .mdc-notched-outline *,
.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused):not(.mdc-select--invalid) .mdc-notched-outline *,
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-color: var(--mdc-theme-border) !important; }


.mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate="true"]) ~ .mdc-checkbox__background {
  border-color: var(--mdc-theme-text-primary-on-background); }


[data-theme="dark"] .mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined),
.mdc-select:not(.mdc-select--disabled):not(.mdc-select--outlined) .mdc-select__anchor,
.mdc-text-field:not(.mdc-text-field--outlined):not(.mdc-text-field--disabled) {
  background-color: var(--mdc-theme-surface) !important; }


.mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input,
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.mdc-select.mdc-select--disabled .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-border) !important; }


.mdc-text-field--wrong:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input {
  caret-color: var(--mdc-theme-error) !important; }

.mdc-text-field--wrong:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-line-ripple:after {
  border-color: var(--mdc-theme-error) !important; }

.mdc-text-field--wrong:not(.mdc-text-field--disabled) .mdc-floating-label,
.mdc-text-field--wrong:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text,
.mdc-text-field.mdc-text-field--invalid .mdc-floating-label,
.mdc-floating-label.mdc-floating-label--shake,
.mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: var(--mdc-theme-error) !important; }


/* Bei den Einträgen sollte außerdem das href Attribut entfernt werden */
.mdc-list-item--disabled > * {
  opacity: .5;
  user-select: none;
  pointer-events: none; }

.mdc-list-item--disabled:hover > * {
  cursor: not-allowed !important; }


.mdc-list-item--disabled:hover {
  cursor: not-allowed !important; }


.mdc-dialog__title,
.mdc-tab__text-label {
  color: var(--mdc-theme-text-primary-on-background) !important; }


.mdc-tab--active .mdc-tab__text-label {
  color: var(--mdc-theme-primary) !important; }


div.mdc-dialog__content > div,
div.mdc-dialog__surface > div,
.mdc-button--raised.mdc-button--disabled.mdc-ripple-upgraded, .mdc-button--unelevated.mdc-button--disabled.mdc-ripple-upgraded {
  color: var(--mdc-theme-text-secondary-on-light) !important; }


.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter, .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: var(--mdc-theme-text-secondary-on-light) !important; }


.mdc-drawer,
.mdc-list-divider {
  border-color: var(--mdc-theme-border) !important; }


.mdc-top-app-bar {
  z-index: 7;
  position: absolute; }


.mdc-drawer-app-content {
  flex: auto;
  overflow: auto;
  position: relative; }


[data-theme="unicorn"] .mdc-drawer__content {
  background: url("http://24.media.tumblr.com/8210fd413c5ce209678ef82d65731443/tumblr_mjphnqLpNy1s5jjtzo1_400.gif") bottom no-repeat; }


.react-drawer-link--disabled {
  pointer-events: none; }


.react-drawer-link--disabled li,
.mdc-icon-button:disabled {
  cursor: not-allowed !important;
  user-select: none;
  color: var(--mdc-theme-text-disabled-on-background) !important; }


.mdc-top-app-bar__section > .mdc-icon-button:disabled {
  color: var(--mdc-theme-text-disabled-on-dark) !important; }


.react-drawer-link--disabled > li > i {
  cursor: not-allowed !important;
  color: var(--mdc-theme-text-disabled-on-dark) !important; }


.mdc-select--focused .mdc-select__dropdown-icon {
  filter: invert(32%) sepia(92%) saturate(3788%) hue-rotate(220deg) brightness(101%) contrast(101%); }


.rmwc-data-table--sticky-rows-1 .rmwc-data-table__head .rmwc-data-table__row:nth-child(-n + 1) .rmwc-data-table__cell {
  position: sticky;
  top: 0;
  z-index: 2;
  box-shadow: 0 1px var(--mdc-theme-border);
  background-color: var(--mdc-theme-surface, white); }


.mdc-data-table__row,
.mdc-data-table {
  border-color: var(--mdc-theme-border); }


.mdc-text-field--disabled .mdc-text-field__input,
.mdc-text-field--disabled .mdc-floating-label {
  color: var(--mdc-theme-text-disabled-on-background); }


.mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__leading,
.mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__notch,
.mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-border); }


.mdc-radio .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle {
  border-color: var(--mdc-theme-text-primary-on-background); }

.mdc-chip--dark .mdc-chip__checkmark-svg {
  filter: invert(1); }

.mdc-button--raised .mdc-button__icon,
.mdc-button--raised .mdc-button__label {
  color: var(--mdc-theme-on-primary); }


.mdc-drawer__bottom {
  bottom: 0;
  position: fixed;
  width: 255px;
  background: var(--mdc-theme-background);
  color: var(--mdc-theme-text-primary-on-background); }

.mdc-drawer__bottom > p {
  padding: 10px;
  border-top: 1px Solid var(--mdc-theme-border);
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.mdc-chip--error {
  background-color: var(--mdc-theme-error) !important; }

.mdc-chip--error .mdc-chip__icon,
.mdc-chip--error .mdc-chip__text {
  color: var(--mdc-theme-on-error) !important; }

.material-icons {
  user-select: none; }

